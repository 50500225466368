import React from 'react';
import { Preloader, Bars } from 'react-preloader-icon';

import { Container } from './styles';

const Button = ({
  text,
  opacity,
  disabled,
  cursor,
  type,
  loading,
  marginBottom,
  onClick
  }) => {

  return(
    <Container
    type={type}
    isLoading={loading}
    marginBottom={marginBottom}
    style={{opacity, cursor}}
    disabled={disabled}
    onClick={onClick}
    >
      {loading ?
        <Preloader
        use={Bars}
        size={30}
        strokeWidth={8}
        duration={1000}
        />
        : text }

    </Container>
  );
}

export default Button;
